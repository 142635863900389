var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6 pb-8 pt-5 pt-md-8",style:(_vm.sitePageTheme.header)}),_c('div',{staticClass:"container-fluid mt--7",style:(_vm.sitePageTheme.body)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card shadow",class:_vm.type === 'dark' ? 'bg-default' : ''},[_c('a-spin',{attrs:{"tip":"Please wait ...","spinning":_vm.formLoading}},[_c('div',[_c('div',{staticClass:"card-header border-0",class:_vm.type === 'dark' ? 'bg-transparent' : ''},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-3"},[_c('h3',{staticClass:"mb-0",class:_vm.type === 'dark' ? 'text-white' : ''},[_vm._v(" Your Profile ")])]),(_vm.clpSitePageSettings.allow_self_update)?_c('div',{staticClass:"col-md-9 text-right"},[_c('div',[(
                                _vm.requestOtpForUpdatingEmail ||
                                  _vm.requestOtpForUpdatingMobile
                              )?_c('a-popconfirm',{attrs:{"placement":"bottom","ok-text":"Yes","cancel-text":"No"},on:{"confirm":function () {
                                  _vm.$v.formData.$touch();

                                  if (!_vm.$v.formData.$invalid) {
                                    if (_vm.requestOtpForUpdatingEmail) {
                                      _vm.requestOtpForEmail();
                                      _vm.startSmsTimer();
                                    }
                                    if (_vm.requestOtpForUpdatingMobile) {
                                      _vm.requestOtpForMobile();
                                      _vm.startEmailTimer();
                                    }
                                  }
                                }}},[_c('template',{slot:"title"},[_c('p',[_vm._v(" Are you sure you want to update you profile? ")])]),_c('base-button',{staticClass:"float-right ml-1",attrs:{"id":"new","type":"primary","size":"sm","disabled":_vm.formSubmitting}},[(!_vm.formSubmitting)?_c('span',[_vm._v("Update Profile")]):_c('span',[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Updating ...")],1)])],2):_c('a-popconfirm',{attrs:{"placement":"bottom","ok-text":"Yes","cancel-text":"No"},on:{"confirm":_vm.updateProfile}},[_c('template',{slot:"title"},[_c('p',[_vm._v(" Are you sure you want to update you profile? ")])]),_c('base-button',{staticClass:"float-right ml-1",attrs:{"id":"new","type":"primary","size":"sm","disabled":_vm.formSubmitting}},[(!_vm.formSubmitting)?_c('span',[_vm._v("Update Profile")]):_c('span',[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Updating ...")],1)])],2)],1)]):_vm._e()])]),_c('div',{staticClass:"col"},[_c('form',{staticStyle:{"padding":"1.5rem"},attrs:{"role":"form"}},[_c('h6',{staticClass:"heading-small text-muted text-truncate"},[_c('span',[_vm._v("Personal Info")])]),_c('div',{staticClass:"row"},[(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.first_name &&
                                _vm.fieldSettings.first_name.is_visible
                            )?_c('div',{staticClass:"col-sm-4 col-12"},[_c('base-input',{attrs:{"label":"First Name","required":_vm.isMandatory('first_name')}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.first_name.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.first_name"}}),(_vm.isMandatory('first_name'))?_c('div',[(
                                    _vm.$v.formData.first_name &&
                                      _vm.$v.formData.first_name.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(_vm.$v.formData.first_name.$invalid)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.middle_name.is_visible
                            )?_c('div',{staticClass:"col-sm-3 col-12"},[_c('base-input',{attrs:{"label":"Middle Name","required":_vm.isMandatory('middle_name')}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.middle_name.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.middle_name),callback:function ($$v) {_vm.$set(_vm.formData, "middle_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.middle_name"}}),(_vm.isMandatory('middle_name'))?_c('div',[(
                                    _vm.$v.formData.middle_name &&
                                      _vm.$v.formData.middle_name.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.middle_name.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.last_name.is_visible
                            )?_c('div',{staticClass:"col-sm-4 col-12"},[_c('base-input',{attrs:{"label":"Last Name","required":_vm.isMandatory('last_name')}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.last_name.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.last_name"}}),(_vm.isMandatory('last_name'))?_c('div',[(
                                    _vm.$v.formData.last_name &&
                                      _vm.$v.formData.last_name.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.last_name.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"col-sm-1 col-12"},[(
                                _vm.fieldSettings &&
                                  _vm.fieldSettings.last_name_suffix &&
                                  _vm.fieldSettings.last_name_suffix.is_visible
                              )?_c('base-input',{attrs:{"required":_vm.isMandatory('last_name_suffix'),"label":"Suffix"}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.last_name_suffix
                                    .is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('last_name_suffix')},model:{value:(_vm.formData.last_name_suffix),callback:function ($$v) {_vm.$set(_vm.formData, "last_name_suffix", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.last_name_suffix"}}),(_vm.isMandatory('last_name_suffix'))?_c('div',[(
                                    _vm.$v.formData.last_name_suffix &&
                                      _vm.$v.formData.last_name_suffix.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(
                                      !_vm.$v.formData.last_name_suffix.required
                                    )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1):_vm._e()],1)]),_c('div',{staticClass:"row"},[(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.birth_month.is_visible &&
                                _vm.fieldSettings.birth_day.is_visible &&
                                _vm.fieldSettings.birth_year.is_visible
                            )?_c('div',{staticClass:"col-sm-5 col-12"},[_c('base-input',{attrs:{"label":"Birthday"}},[_c('div',{staticClass:"row"},[(_vm.fieldSettings.birth_month.is_visible)?_c('div',{staticClass:"col-sm-4 col-12"},[_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.birth_month
                                        .is_editable ||
                                        !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('birth_month')},model:{value:(_vm.formattedBirthMonth),callback:function ($$v) {_vm.formattedBirthMonth=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formattedBirthMonth"}},_vm._l((_vm.options.months),function(month){return _c('a-select-option',{key:month.key,attrs:{"value":month.key}},[_vm._v(" "+_vm._s(month.name)+" ")])}),1),_c('small',{staticClass:"text-muted"},[_c('i',[_vm._v("Month")]),(_vm.isMandatory('birth_month'))?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('br'),(_vm.isMandatory('birth_month'))?_c('div',[(
                                          _vm.$v.formData.birth_month &&
                                            _vm.$v.formData.birth_month.$dirty
                                        )?_c('div',{staticClass:"text-danger"},[(
                                            !_vm.$v.formData.birth_month.required
                                          )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()])],1):_vm._e(),_vm._v(" / "),(_vm.fieldSettings.birth_day.is_visible)?_c('div',{staticClass:"col-sm-3 col-12"},[_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.birth_day.is_editable ||
                                        !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('birth_day')},model:{value:(_vm.formattedBirthDay),callback:function ($$v) {_vm.formattedBirthDay=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formattedBirthDay"}},_vm._l((31),function(day){return _c('a-select-option',{key:_vm.zeroPad(day, 2),attrs:{"value":_vm.zeroPad(day, 2)}},[_vm._v(" "+_vm._s(_vm.zeroPad(day, 2))+" ")])}),1),_c('small',{staticClass:"text-muted"},[_c('i',[_vm._v("Day")]),(_vm.isMandatory('birth_day'))?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('br'),(_vm.isMandatory('birth_day'))?_c('div',[(
                                          _vm.$v.formData.birth_day &&
                                            _vm.$v.formData.birth_day.$dirty
                                        )?_c('div',{staticClass:"text-danger"},[(
                                            !_vm.$v.formData.birth_day.required
                                          )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()])],1):_vm._e(),_vm._v(" / "),(_vm.fieldSettings.birth_year.is_visible)?_c('div',{staticClass:"col-sm-4 col-12"},[_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.birth_year.is_editable ||
                                        !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('birth_year')},model:{value:(_vm.formattedBirthYear),callback:function ($$v) {_vm.formattedBirthYear=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formattedBirthYear"}},_vm._l((101),function(year){return _c('a-select-option',{key:parseInt(_vm.yearStart) + parseInt(year),attrs:{"value":parseInt(_vm.yearStart) + parseInt(year)}},[_vm._v(" "+_vm._s(parseInt(_vm.yearStart) + parseInt(year))+" ")])}),1),_c('small',{staticClass:"text-muted"},[_c('i',[_vm._v("Year")]),(_vm.isMandatory('birth_year'))?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('br'),(_vm.isMandatory('birth_year'))?_c('div',[(
                                          _vm.$v.formData.birth_year &&
                                            _vm.$v.formData.birth_year.$dirty
                                        )?_c('div',{staticClass:"text-danger"},[(
                                            !_vm.$v.formData.birth_year.required
                                          )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()])],1):_vm._e()])])],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.gender &&
                                _vm.fieldSettings.gender.is_visible
                            )?_c('div',{staticClass:"col-sm-4 col-12"},[_c('base-input',{attrs:{"label":"Gender","required":_vm.isMandatory('gender')}},[_c('br'),_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.gender.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('gender')},model:{value:(_vm.formData.gender),callback:function ($$v) {_vm.$set(_vm.formData, "gender", $$v)},expression:"formData.gender"}},_vm._l((_vm.options.genders),function(gender){return _c('a-select-option',{key:gender.key,attrs:{"value":gender.key}},[_vm._v(" "+_vm._s(gender.name)+" ")])}),1),(_vm.isMandatory('gender'))?_c('div',[(
                                    _vm.$v.formData.gender &&
                                      _vm.$v.formData.gender.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.gender.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.nick_name &&
                                _vm.fieldSettings.nick_name.is_visible
                            )?_c('div',{staticClass:"col-sm-3 col-12"},[_c('base-input',{attrs:{"label":"Nickname","required":_vm.isMandatory('nick_name')}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.nick_name.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.nick_name),callback:function ($$v) {_vm.$set(_vm.formData, "nick_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.nick_name"}}),(_vm.isMandatory('nick_name'))?_c('div',[(
                                    _vm.$v.formData.nick_name &&
                                      _vm.$v.formData.nick_name.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.nick_name.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e()]),(_vm.fieldSettings && (
                            ( _vm.fieldSettings.location_region && _vm.fieldSettings.location_region.is_visible) ||
                            ( _vm.fieldSettings.location_province && _vm.fieldSettings.location_province.is_visible) ||
                            ( _vm.fieldSettings.location_barangay && _vm.fieldSettings.location_barangay.is_visible) ||
                            ( _vm.fieldSettings.location_city && _vm.fieldSettings.location_city.is_visible) ||
                            ( _vm.fieldSettings.location_unit && _vm.fieldSettings.location_unit.is_visible) ||
                            ( _vm.fieldSettings.location_street && _vm.fieldSettings.location_street.is_visible)
                            ))?_c('div',[_c('hr'),_c('h6',{staticClass:"heading-small text-muted text-truncate"},[_c('span',[_vm._v("Address")])]),_c('div',{staticClass:"row d-flex flex-column flex-md-row"},[(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.location_region &&
                                _vm.fieldSettings.location_region.is_visible
                            )?_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Region","required":_vm.isMandatory('location_region')}},[_c('br'),_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.location_region
                                    .is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('location_region')},on:{"change":_vm.onChangeRegion},model:{value:(_vm.formData.region_code),callback:function ($$v) {_vm.$set(_vm.formData, "region_code", $$v)},expression:"formData.region_code"}},_vm._l((_vm.options.regions),function(region){return _c('a-select-option',{key:region.code,attrs:{"value":region.code}},[_vm._v(" "+_vm._s(region.regionName)+" - "+_vm._s(region.name)+" ")])}),1),(_vm.isMandatory('location_region'))?_c('div',[(
                                    _vm.$v.formData.location_region &&
                                      _vm.$v.formData.location_region.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(
                                      !_vm.$v.formData.location_region.required
                                    )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.location_province &&
                                _vm.fieldSettings.location_province.is_visible &&
                                !_vm.isRegionNCR
                            )?_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Province","required":_vm.isMandatory('location_province')}},[_c('br'),_c('a-select',{attrs:{"disabled":!_vm.fieldSettings.location_province
                                    .is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update,"required":_vm.isMandatory('location_province')},on:{"change":_vm.onChangeProvince},model:{value:(_vm.formData.province_code),callback:function ($$v) {_vm.$set(_vm.formData, "province_code", $$v)},expression:"formData.province_code"}},_vm._l((_vm.options.provinces),function(province){return _c('a-select-option',{key:province.code,attrs:{"value":province.code}},[_vm._v(" "+_vm._s(province.name)+" ")])}),1),(_vm.isMandatory('location_province'))?_c('div',[(
                                    _vm.$v.formData.location_province &&
                                      _vm.$v.formData.location_province.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(
                                      !_vm.$v.formData.location_province.required
                                    )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.location_city &&
                                _vm.fieldSettings.location_city.is_visible
                            )?_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"City/Municipality","required":_vm.isMandatory('location_city')}},[_c('br'),_c('a-select',{attrs:{"required":_vm.isMandatory('location_city'),"disabled":!_vm.fieldSettings.location_city.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},on:{"change":_vm.onChangeCity},model:{value:(_vm.municity),callback:function ($$v) {_vm.municity=$$v},expression:"municity"}},_vm._l((_vm.options.cities),function(city){return _c('a-select-option',{key:city.code,attrs:{"value":city.code,"data-city":String(city.isCity)}},[_vm._v(" "+_vm._s(city.name)+" ")])}),1),(_vm.isMandatory('location_city'))?_c('div',[(
                                    _vm.$v.municity &&
                                      _vm.$v.municity.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.municity.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e()]),_c('div',{staticClass:"row d-flex flex-column flex-md-row"},[(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.location_barangay &&
                                _vm.fieldSettings.location_barangay.is_visible
                            )?_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Barangay","required":_vm.isMandatory('location_barangay')}},[_c('br'),_c('a-select',{attrs:{"required":_vm.isMandatory('location_barangay'),"disabled":!_vm.fieldSettings.location_barangay
                                    .is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},on:{"change":_vm.onChangeBarangay},model:{value:(_vm.formData.barangay_code),callback:function ($$v) {_vm.$set(_vm.formData, "barangay_code", $$v)},expression:"formData.barangay_code"}},_vm._l((_vm.options.barangays),function(barangay){return _c('a-select-option',{key:barangay.code,attrs:{"value":barangay.code}},[_vm._v(" "+_vm._s(barangay.name)+" ")])}),1),(_vm.isMandatory('location_barangay'))?_c('div',[(
                                    _vm.$v.formData.location_barangay &&
                                      _vm.$v.formData.location_barangay.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(
                                      !_vm.$v.formData.location_barangay.required
                                    )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.zipcode &&
                                _vm.fieldSettings.zipcode.is_visible
                            )?_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Zipcode","required":_vm.isMandatory('zipcode')}},[_c('a-input',{attrs:{"required":_vm.isMandatory('zipcode'),"disabled":!_vm.fieldSettings.zipcode.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.zipcode),callback:function ($$v) {_vm.$set(_vm.formData, "zipcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.zipcode"}})],1),(_vm.isZipcodeError)?_c('div',[_c('div',{staticClass:"text-danger"},[_c('div',{staticClass:"error"},[_vm._v(" Field only accepts number ")])])]):_vm._e(),(_vm.isMandatory('zipcode'))?_c('div',[(
                                  _vm.$v.formData.zipcode &&
                                    _vm.$v.formData.zipcode.$dirty
                                )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.zipcode.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1):_vm._e()]),(
                            _vm.fieldSettings &&
                              _vm.fieldSettings.location_unit &&
                              _vm.fieldSettings.location_unit.is_visible &&
                              _vm.fieldSettings.location_street.is_visible
                          )?_c('div',{staticClass:"row"},[(_vm.fieldSettings.location_unit.is_visible)?_c('div',{staticClass:"col-sm-3 col-12"},[_c('base-input',{attrs:{"label":"Unit No.","required":_vm.isMandatory('location_unit')}},[_c('a-input',{attrs:{"required":_vm.isMandatory('location_unit'),"disabled":!_vm.fieldSettings.location_unit.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.location_unit),callback:function ($$v) {_vm.$set(_vm.formData, "location_unit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.location_unit"}})],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.location_street &&
                                _vm.fieldSettings.location_street.is_visible
                            )?_c('div',{staticClass:"col-sm-9 col-12"},[_c('base-input',{attrs:{"label":"Street"}},[_c('a-input',{attrs:{"disabled":!_vm.fieldSettings.location_street
                                    .is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update},model:{value:(_vm.formData.location_street),callback:function ($$v) {_vm.$set(_vm.formData, "location_street", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.location_street"}}),(_vm.isMandatory('location_street'))?_c('div',[(
                                    _vm.$v.formData.location_street &&
                                      _vm.$v.formData.location_street.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(
                                      !_vm.$v.formData.location_street.required
                                    )?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e()]):_vm._e()]):_vm._e(),_c('hr'),_c('h6',{staticClass:"heading-small text-muted text-truncate"},[_c('span',[_vm._v("Contact Details")])]),_c('div',{staticClass:"row"},[(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.mobile_no &&
                                _vm.fieldSettings.mobile_no.is_visible
                            )?_c('div',{staticClass:"col-sm-6 col-12"},[_c('base-input',{attrs:{"label":"Mobile Number","required":_vm.isMandatory('mobile_no')}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{style:(this.windowWidth < 768 ? {width:'30%'}: {width:'15%'}),attrs:{"default-value":"+63","disabled":!_vm.fieldSettings.mobile_no.is_editable ||
                                      !_vm.clpSitePageSettings.allow_self_update ||
                                      !_vm.canUpdateMobile},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}},[_c('a-select-option',{attrs:{"value":"+63"}},[_vm._v(" +63 ")])],1),_c('a-input',{style:(this.windowWidth < 768 ? {width:'70%'}: {width:'85%'}),attrs:{"required":_vm.isMandatory('mobile_no'),"placeholder":"9XXXXXXXXX","disabled":!_vm.fieldSettings.mobile_no.is_editable ||
                                      !_vm.clpSitePageSettings.allow_self_update ||
                                      !_vm.canUpdateMobile},model:{value:(_vm.mobileNo),callback:function ($$v) {_vm.mobileNo=$$v},expression:"mobileNo"}})],1),(
                                  _vm.$v.formData.mobile_no &&
                                    _vm.$v.formData.mobile_no.$dirty
                                )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.mobile_no.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):(
                                    !_vm.$v.formData.mobile_no.mobileNoFormat
                                  )?_c('div',{staticClass:"error"},[_vm._v(" Invalid mobile number format. Use +639XXXXXXXXX format. ")]):_vm._e()]):_vm._e()],1)],1):_vm._e(),(
                              _vm.fieldSettings &&
                                _vm.fieldSettings.email &&
                                _vm.fieldSettings.email.is_visible
                            )?_c('div',{staticClass:"col-sm-6 col-12"},[_c('base-input',{attrs:{"label":"Email Address","required":_vm.isMandatory('email')}},[_c('a-input',{attrs:{"required":_vm.isMandatory('email'),"disabled":!_vm.fieldSettings.email.is_editable ||
                                    !_vm.clpSitePageSettings.allow_self_update ||
                                    !_vm.canUpdateEmail},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.email"}}),(_vm.isMandatory('email'))?_c('div',[(
                                    _vm.$v.formData.email &&
                                      _vm.$v.formData.email.$dirty
                                  )?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.email.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()]):_vm._e()],1)],1):_vm._e()]),(_vm.dynamic_form.length > 0)?_c('hr'):_vm._e(),(_vm.dynamic_form.length > 0)?_c('h6',{staticClass:"heading-small text-muted text-truncate"},[_c('span',[_vm._v("Other Info")])]):_vm._e(),(_vm.dynamic_form.length > 0)?_c('div',{staticClass:"row"},[_c('dynamic-form',{key:_vm.dynamicFormKey,ref:"dynamicForm",attrs:{"convertedJson":{
                              mode: 'tabs',
                              groups: _vm.dynamic_form
                            },"disabled":_vm.viewMode,"formSettings":_vm.memberDataMeta,"formData":_vm.formData,"membershipPrograms":_vm.options.membershipPrograms,"membershipLevels":_vm.options.membershipLevels},on:{"valueUpdated":_vm.updateSettings}})],1):_vm._e()])])])])],1)])])])])])]),_c('a-modal',{attrs:{"title":"OTP Verification Form"},on:{"ok":function () {
        _vm.updateProfile();
      },"cancel":_vm.resetOtpTimers},model:{value:(_vm.otpRequestModal),callback:function ($$v) {_vm.otpRequestModal=$$v},expression:"otpRequestModal"}},[(_vm.requestOtpForUpdatingEmail)?_c('div',[_c('p',[_vm._v("Please enter the OTP sent to your "),_c('b',[_vm._v("Mobile Number")])]),_c('small',[_vm._v("This authentication is to verify that you are updating your email.")]),_c('a-input-search',{attrs:{"placeholder":"Enter received OTP from mobile number"},on:{"search":_vm.resendOtpForEmail},model:{value:(_vm.otpCode),callback:function ($$v) {_vm.otpCode=$$v},expression:"otpCode"}},[(_vm.resendSmsTimer > 0)?_c('a-button',{attrs:{"slot":"enterButton","disabled":!_vm.canResendSmsOtp},slot:"enterButton"},[_vm._v(" Resend in "+_vm._s(_vm.resendSmsTimer)+"(s) ")]):_c('a-button',{attrs:{"slot":"enterButton","disabled":!_vm.canResendSmsOtp || _vm.resendingSmsOtp},slot:"enterButton"},[_vm._v(" Resend OTP Request ")])],1)],1):_vm._e(),(_vm.requestOtpForUpdatingMobile)?_c('div',[_c('p',[_vm._v("Please enter the OTP sent to your "),_c('b',[_vm._v("Email")])]),_c('small',[_vm._v("This authentication is to verify that you are updating your mbile number.")]),_c('a-input-search',{attrs:{"placeholder":"Enter received OTP from email"},on:{"search":_vm.resendOtpForMobile},model:{value:(_vm.otpEmailCode),callback:function ($$v) {_vm.otpEmailCode=$$v},expression:"otpEmailCode"}},[(_vm.resendEmailTimer > 0)?_c('a-button',{attrs:{"slot":"enterButton","disabled":!_vm.canResendEmailOtp},slot:"enterButton"},[_vm._v(" Resend in "+_vm._s(_vm.resendEmailTimer)+"(s) ")]):_c('a-button',{attrs:{"slot":"enterButton","disabled":!_vm.canResendEmailOtp || _vm.resendingEmailOtp},slot:"enterButton"},[_vm._v(" Resend OTP Request ")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }